document.addEventListener('click', e => {
    if (e.target.closest('a') && e.target.closest('a').href && (e.target.closest('.boldr-agenda-categories') || e.target.closest('.boldr-agenda-date') || e.target.closest('.boldr-agenda-items-empty a')))
    {
        e.preventDefault();
        fetch(e.target.closest('a').href).then(r => r.text()).then(html => {
            let section = e.target.closest('.boldr-agenda');
            let newDocument = document.createElement('div');
            newDocument.innerHTML = html;
            let newSection = newDocument.querySelector('.boldr-agenda');
            console.log(newSection.innerHTML);
            section.innerHTML = newSection.innerHTML;
        });
    }
})
